import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

class Home extends React.Component {
  render() {
    const data = this.props.data.wordpressPage.acf
    const image =
      data.background_image.localFile !== null
        ? data.background_image.localFile.childImageSharp.fluid
        : data.background_image.source_url

    const imageIsAvailableLocal = data.background_image.localFile !== null

    return (
      <Link to="/login">
        <LandingPage>
          {imageIsAvailableLocal ? (
            <Img
              fluid={image}
              critical
              style={{
                userSelect: 'none',
                position: 'absolute',
                width: '100%',
                zIndex: 1,
              }}
              alt={data.headline}
            />
          ) : (
            <img
              src={image}
              style={{
                userSelect: 'none',
                position: 'absolute',
                width: '100%',
                zIndex: 1,
              }}
              alt={data.headline}
            />
          )}
          <Content>
            <Subline>
              <strong>ECCO</strong> presents
            </Subline>
            <Headline>{data.headline}</Headline>
          </Content>
        </LandingPage>
      </Link>
    )
  }
}

export default Home

export const pageQuery = graphql`
  query {
    wordpressPage(template: { eq: "page-landingpage.php" }) {
      acf {
        headline
        background_image {
          source_url
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 2000) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  }
`

const Content = styled.div`
  position: relative;
  z-index: 2;
  text-align: center;
`

const LandingPage = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Subline = styled.span`
  display: block;
  font-size: 17px;
  text-transform: uppercase;
  margin-bottom: 40px;
  letter-spacing: 4px;
`

const Headline = styled.span`
  display: block;
  font-size: 50px;
  text-transform: uppercase;
  letter-spacing: 4px;
`
